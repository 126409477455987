import React, { useState, useEffect } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import {
    faGithub,
    faInstagram,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

const iconMap = {
    'github': faGithub,
    'instagram': faInstagram,
    'linkedin': faLinkedin,
    'download VCF': faFileDownload,
    'email': faEnvelope
    // Assuming you're using this for 'Download VCF'
    // ...add other event types and their corresponding icons
};

const EventCounter = () => {
    const [eventCounts, setEventCounts] = useState({});

    useEffect(() => {
        // Listen for real-time updates
        const q = query(collection(db, "events"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            // Start with a fresh state
            const freshCounts = {};

            // Go through all the documents in the snapshot
            snapshot.docs.forEach((doc) => {
                const eventData = doc.data();
                const { event } = eventData;
                if (event) {
                    freshCounts[event] = (freshCounts[event] || 0) + 1;
                }
            });

            // Update state once after processing the entire snapshot
            setEventCounts(freshCounts);
        });

        // Detach listener when the component unmounts
        return () => unsubscribe();
    }, []); // Removed eventCounts from dependencies


    // UI to display the event counts
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: 'white'
        }}>
            {Object.entries(eventCounts).map(([eventType, count]) => (
                <div key={eventType} style={{ margin: '10px' }}>
                    <FontAwesomeIcon icon={iconMap[eventType] || faFileDownload} /> {eventType}: {count}
                </div>
            ))}
        </div>
    );
};

export default EventCounter;
