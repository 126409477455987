import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import './App.css';  // Ensure the path is correct based on your structure
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';


function Mobile() {

    let globalIp = 'Unknown';
    let Gcountry_name = 'Unknown';

    useEffect(() => {
        const logEvent = async () => {
            try {
                //TODO: Uncomment this block to fetch the client's IP and country information
                // Fetch the client's IP and country information
                // const {data} = await axios.get('https://ipapi.co/json/');
                // const {ip, country_name} = data;
                // globalIp = ip;
                // Gcountry_name = country_name;
                addToCollection("Download VCF");
                window.location.href = 'https://alfonsoridao.s3.amazonaws.com/alfonsoridao.vcf';
            } catch (error) {
                console.error('Error:', error);
            }
        }
        logEvent();
    }, []);


    function addToCollection(event) {
        const eventTime = new Date();
        addDoc(collection(db, 'events'), {
            timestamp: eventTime,
            ip: globalIp,
            country: Gcountry_name,
            event: event
        });
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', backgroundColor: 'white' }}>
            <h1>
                <span style={{ color: '#18417e' }}>alfonso</span>
                <span style={{ color: '#b1bdd3' }}>ridao</span>
            </h1>
            <div style={{fontSize: '24px', color: '#8ba0be'}}>
                {/*<a href="https://alfonso.ridao.ar" style={{margin: '10px', color: '#8ba0be'}}>*/}
                {/*    <FontAwesomeIcon icon={faLink}/>*/}
                {/*</a>*/}
                <a href="https://github.com/fonCki"
                   style={{margin: '10px', color: '#8ba0be'}}
                   onClick={() => {addToCollection('github')}}
                >
                    <FontAwesomeIcon icon={faGithub}/>
                </a>
                <a href="https://www.instagram.com/alfonsoridao/"
                   style={{margin: '10px', color: '#8ba0be'}}
                     onClick={() => {addToCollection('instagram')}}
                >
                    <FontAwesomeIcon icon={faInstagram}/>
                </a>
                <a href="https://www.linkedin.com/in/alfonsoridao/"
                   style={{margin: '10px', color: '#8ba0be'}}
                        onClick={() => {addToCollection('linkedin')}}
                >
                    <FontAwesomeIcon icon={faLinkedin}/>
                </a>
                <a href="mailto:alfonso@ridao.ar"
                   style={{margin: '10px',color: '#8ba0be'}}
                        onClick={() => {addToCollection('email')}}
                >
                    <FontAwesomeIcon icon={faEnvelope}/>
                </a>
            </div>
        </div>
    );
}

export default Mobile;
