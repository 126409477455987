import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import Mobile from './Mobile';
import EventCounter from "./EventCounter/EventCounter";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/mobile" element={<Mobile />} />
                <Route path="/event-counter" element={<EventCounter />} /> {/* Add this line */}
            </Routes>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
