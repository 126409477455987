import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile || isTablet) {
      navigate('/mobile'); // Only navigate, don't mix with window.location
    } else {
      window.location.href = 'https://alfonso.ridao.ar'; // Redirect for desktop
    }
  }, [navigate]);

  return <div></div>;
}

export default App;
